<template>
    <div class="Member">
        <div class="swiper" ref="swiper">
            <div class="primary">
                <div class="avatar" :style="{ backgroundImage: `url(${ userProfile.avatar })` }" />
                <h4>{{ userProfile.firstName }} {{ userProfile.lastName }}</h4>
            </div>
            <h4 :class="{ light: !alt && !profit, gold: profit && (bookProfit > 0), red: profit && (bookProfit < 0) }" >{{ profit ? `${ (bookProfit < 0) ? '-' : '' }$${ Math.abs(bookProfit).toFixed(2) }` : info }}</h4>
        </div>
        <div v-if="hasInvite" class="invite action" :class="{ hide: position !== 'right' }" @click="$emit('invite', id)">
            <img src="/images/invite-gold.svg" alt="Invite" />
        </div>
        <div v-if="hasPromote" class="promote action" :class="{ hide: position !== 'right' }" @click="$emit('promote', id)">
            <img src="/images/promote.svg" alt="Promote" />
        </div>
        <div v-if="hasDemote" class="demote action" :class="{ hide: position !== 'right' }" @click="$emit('demote', id)">
            <img src="/images/demote.png" alt="Demote" />
        </div>
        <div v-if="hasDelete" class="delete action" :class="{ hide: position !== 'left' }" @click="$emit('delete', id)">
            <img src="/images/delete.svg" alt="Delete" />
        </div>
    </div>
</template>

<script>

    // Modules.
    import Hammer from 'hammerjs';
    import gsap from 'gsap';

    // Misc.
    import { getTicketStatus, adjustedOdds } from '@/utils';

    export default {
        name: 'Member',
        props: {
            id: String,
            userProfile: Object,
            alt: String,
            lock: { type: String, default: 'all', validator: value => {
                return ['none', 'left', 'right', 'all'].indexOf(value) !== -1;
            }},
            hasInvite: Boolean,
            hasDelete: Boolean,
            hasPromote: Boolean,
            hasDemote: Boolean,
            profit: Boolean,
            wagers: Object,
            bets: Object,
            book: Object,
        },
        data() {
            return {
                position: 'center',
            }
        },
        computed: {
            info() {
                return this.$props.alt || this.$props.userProfile.username;
            },
            bookProfit() {

                let profit = 0;

                if (!this.userProfile.bets) return profit;
                for (const id of Object.keys(this.userProfile.bets)) {

                    const ticket = this.bets[id];
                    if (!ticket) continue;

                    if (!ticket.void) {

                        const ticketStatus = getTicketStatus(ticket, this.wagers);

                        if (ticketStatus === 'loss') {
                            profit -= ticket.options.risk;
                        } else if (ticketStatus === 'win') {
                            profit += ((ticket.options.risk * this.adjustedOdds(ticket)) - ticket.options.risk);
                        }

                    }

                }

                return profit;

            }
        },
        mounted() {

            const hammertime = new Hammer(this.$refs.swiper);

            hammertime.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
            hammertime.on('swipe', e => {

                if (this.$props.lock === 'all') return;

                if (e.distance > 75) {
                    switch (true) {
                        case (e.direction === 4 && this.position === 'center' && this.$props.lock !== 'right'):
                            gsap.to(this.$refs.swiper, { x: 50, duration: 0.5, ease: 'expo.out' });
                            this.position = 'right';
                            break;
                        case (e.direction === 4 && this.position === 'left'):
                        case (e.direction === 2 && this.position === 'right'):
                            gsap.to(this.$refs.swiper, { x: 0, duration: 0.5, ease: 'expo.out' });
                            this.position = 'center';
                            break;
                        case (e.direction === 2 && this.position === 'center' && this.$props.lock !== 'left'):
                            gsap.to(this.$refs.swiper, { x: -50, duration: 0.5, ease: 'expo.out' });
                            this.position = 'left';
                            break;
                    }
                }

            });

        },
        methods: {
            adjustedOdds(ticket) {
                return adjustedOdds(ticket, this.wagers);
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .Member {
        position: relative;
        height: 50px;
    }

    .swiper, .action {

        border-radius: 5px;
        box-shadow: 0 0 15px color(Black, 0.1);

        height: 100%;

    }

    .swiper {

        position: relative;
        z-index: 5;

        justify-content: space-between;

        height: 100%;
        padding: 0 size(Medium);

        background-color: color(White);

    }

    .swiper, .primary, .action {
        display: flex;
        align-items: center;
    }

    .primary h4 {
        text-transform: uppercase;
        margin-left: size(Small);
    }

    .light {
        opacity: 0.25;
    }

    .action {

        position: absolute;
        top: 0;

        width: size(Large);

        justify-content: center;

        transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;

    }

    .action.hide {
        opacity: 0;
        visibility: hidden;
    }

    .action img {
        width: 20px;
    }

    .invite, .promote, .demote {
        left: 0;
        background-color: color(White);
    }

    .delete {
        right: 0;
        background-color: color(Carmine);
    }

    h4.red {
        color: color(Carmine);
    }

    h4.gold {
        color: color(OldGold);
    }

</style>
