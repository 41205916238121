<template>
    <section id="InviteMembers">
        <BookSelector :books="managedBooks" :selectedName="profile.books[$route.params.book_id].name" />
        <div v-if="refinedMembers.length > 0">
            <h3>Invite</h3>
            <Member v-for="member in refinedMembers" :key="member[0]" :id="member[0]" :userProfile="member[1]" hasInvite @invite="invite" lock="left" />
        </div>
        <h3 class="no-results" v-else>No results available.</h3>
        <Search @update="refine" />
        <Options :options="options" :selected="0" hideShadow />
    </section>
</template>

<script>

    // Components.
    import BookSelector from '@/components/BookSelector.vue';
    import Options from '@/components/Options.vue';
    import Member from '@/components/Member.vue';
    import Search from '@/components/Search.vue';

    // Firebase.
    import { watchBook, inviteToBook } from '@/firebase/books.js';
    import { getProfiles } from '@/firebase/auth.js';

    export default {
        name: 'InviteMembers',
        components: {
            BookSelector,
            Options,
            Member,
            Search,
        },
        data() {
            return {
                nonMembers: [],
                search: '',
            }
        },
        computed: {
            managedBooks() {
                return Object.entries(this.profile.books).filter(book => book[1].membership === 'bookie' || book[1].membership === 'admin');
            },
            refinedMembers() {

                if (this.search === '') return this.nonMembers;

                return this.nonMembers.filter(profile => {
                    const target = `${ profile[1].firstName } ${ profile[1].lastName } ${ profile[1].username }`.toUpperCase();
                    return target.includes(this.search.toUpperCase());
                });

            },
            options() {

                const bookId = this.$route.params.book_id;

                return [
                    {
                        icon: 'members',
                        name: 'Members',
                        link: { name: 'Members', params: { book_id: bookId } },
                    },
                    {
                        icon: 'wagers',
                        name: 'Wagers',
                        link: { name: 'Wagers', params: { book_id: bookId } },
                    },
                    {
                        icon: 'tickets',
                        name: 'Tickets',
                        link: { name: 'Tickets', params: { book_id: bookId } },
                    },
                    {
                        icon: 'profit',
                        name: 'Profit',
                        link: { name: 'Profit', params: { book_id: bookId } },
                    },
                ];

            },
        },
        mounted() {
            this.$store.dispatch('setNavShadow', true);
        },
        created() {
            this.$store.dispatch('updateLoading', 1);
            watchBook(this.$route.params.book_id).on('value', this.listen);
        },
        watch: {
            $route(to, from) {
                this.$store.dispatch('updateLoading', 1);
                watchBook(from.params.book_id).off('value', this.listen);
                watchBook(to.params.book_id).on('value', this.listen);
            }
        },
        beforeDestroy() {
            watchBook(this.$route.params.book_id).off('value', this.listen);
        },
        methods: {
            listen(snapshot) {

                const val = snapshot.val();
                let keys = [val.bookie];

                if (val.members) {
                    keys = keys.concat(Object.keys(val.members));
                }

                getProfiles().then(snapshot => {

                    this.nonMembers = Object.entries(snapshot.val()).filter(profile => {
                        return !keys.includes(profile[0]);
                    }).sort((a, b) => {
                        return `${ b[1].firstName } ${ b[1].lastName }` < `${ a[1].firstName } ${ a[1].lastName }` ? 1 : -1;
                    });

                    this.$store.dispatch('updateLoading', -1);

                });

            },
            invite(userId) {
                this.$store.dispatch('updateLoading', 1);
                inviteToBook(this.user.uid, userId, this.$route.params.book_id, this.profile.books[this.$route.params.book_id].name).then(() => {
                    this.$store.dispatch('updateLoading', -1);
                });
            },
            refine(term) {
                this.search = term;
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    #InviteMembers {
        overflow: hidden;
        padding: size(Large) size(Medium) (50px + 40px + size(Medium));
    }

    .Member {
        margin-top: size(Small);
    }

    h3 {
        margin-top: size(Medium);
    }

    .no-results {
        text-align: center;
    }

    .Search {

        position: fixed;
        bottom: 50px;
        left: 0;

        width: 100%;

    }

</style>
