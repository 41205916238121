<template>
    <div class="Search" @click="focus">
        <h4>Search</h4>
        <input type="text" v-model="search" ref="input">
        <Close @onClick="erase" v-if="search !== ''" />
    </div>
</template>

<script>

    // Components.
    import Close from '@/components/Close.vue';

    export default {
        name: 'Search',
        components: {
            Close,
        },
        data() {
            return {
                search: '',
            }
        },
        methods: {
            erase() {
                this.search = '';
            },
            focus() {
                this.$refs.input.focus();
            }
        },
        watch: {
            search(to) {
                this.$emit('update', to);
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .Search {

        height: size(Large);
        padding: 0 size(Medium);

        background-color: color(White);
        box-shadow: 0 0 15px color(Black, 0.1);

        display: flex;
        align-items: center;

    }

    h4 {
        text-transform: uppercase;
    }

    input {

        flex-grow: 1;
        box-shadow: none;

        color: color(Emperor, 0.25);
        text-align: right;
        text-transform: uppercase;

        padding: 0 size(Small);

    }

    input:focus {
        border: none;
    }

    .Close {
        flex-shrink: 0;
    }

</style>
